import { defineComponent } from 'vue';
import { german, wochentage } from '@rose/common-ui';
import { settingsPraxisColumnsDefs } from '@/components/columns/settings/praxis';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
export default defineComponent({
  data: function data() {
    return {
      localeText: german,
      gridOptions: {},
      gridApi: {},
      gridColumnApi: {},
      columnDefs: settingsPraxisColumnsDefs(),
      wochentage: wochentage,
      wochentagSelected: {
        tag: '',
        von: '',
        bis: ''
      },
      showSave: false
    };
  },
  computed: {
    oeffnungszeiten: function oeffnungszeiten() {
      return editSettingsStore.getters.oeffnungszeiten;
    },
    showAdd: function showAdd() {
      return !!(this.wochentagSelected.tag && this.wochentagSelected.von && this.wochentagSelected.bis) && !this.bisValidator;
    },
    bisValidator: function bisValidator() {
      if (this.wochentagSelected.von && this.wochentagSelected.bis) {
        return this.wochentagSelected.von > this.wochentagSelected.bis;
      }

      return false;
    }
  },
  created: function created() {
    this.gridOptions = {
      rowHeight: 40,
      defaultColDef: {
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        }
      },
      rowSelection: 'single',
      suppressRowClickSelection: true,
      suppressCellFocus: true
    };
  },
  methods: {
    onGridReady: function onGridReady(event) {
      this.gridApi = event.api;
      this.gridColumnApi = event.columnApi;
      this.gridApi.setDomLayout('autoHeight');
    },
    onCellClicked: function onCellClicked(event) {
      this.$log.debug('Cell clicked', event);
    },
    addOeffnungszeiten: function addOeffnungszeiten() {
      editSettingsStore.commit.addOeffnungszeiten(this.wochentagSelected);
      this.wochentagSelected = {
        tag: '',
        von: '',
        bis: ''
      };
    }
  }
});