import { IBot, ICompleteBotInfo } from '@rose/types';
import { createDirectStore } from 'direct-vuex';
import { collectorApi } from '@/services/data';
import { emptyBotData, demoBotData } from '@/helpers/settings';
import { isDev } from '@rose/common-ui';
import { isEmpty } from 'lodash';
import { authStore } from '@/state/authStore';

export interface BotInfoState {
  botInfo: ICompleteBotInfo | IBot;
}

const botInfoState: BotInfoState = {
  botInfo: {} as IBot,
};

const { store, rootActionContext } = createDirectStore({
  strict: process.env.NODE_ENV !== 'production',
  state: () => botInfoState,
  getters: {},
  mutations: {
    setBotInfo(state, botInfo: ICompleteBotInfo | IBot) {
      state.botInfo = botInfo;
    },
  },
  actions: {
    async init(context) {
      const { state, commit } = rootActionContext(context);
      let botInfo: ICompleteBotInfo | IBot = emptyBotData();

      try {
        botInfo = await collectorApi.bot.bot();
      } catch (err) {
        console.error(`could not get bot info`, err);
      }

      if (botInfo === emptyBotData() && isDev) {
        botInfo = demoBotData();
      }

      if (isEmpty(state.botInfo)) {
        console.log('initializing botInfoState', botInfo);
      } else {
        console.log('reset botInfoState', botInfo);
      }
      commit.setBotInfo(botInfo);
    },
    async updateExternalIp(context) {
      const { state, dispatch } = rootActionContext(context);
      try {
        const res = await collectorApi.bot.updateExternalIp(state.botInfo.cid);

        console.log('updateExternalIp', res);
        if (!res.success) {
          await authStore.dispatch.notifyError(`IP-Adresse konnte nicht aktualisiert werden: ${res.error}`);
          return;
        }
        void authStore.dispatch.notifySuccess(`IP-Adresse aktualisiert: ${res.ip}`);
        if (state.botInfo.ipExternal !== res.ip) {
          await dispatch.init();
        }
      } catch (err) {
        void authStore.dispatch.notifyError(`IP-Adresse konnte nicht aktualisiert werden`);
      }
    },
  },
});

export const botInfoStore = store;
