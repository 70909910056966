import { uhrFilter, IRoseAGGridColumn } from '@rose/common-ui';
import { IZeiten } from '@rose/types';
import { editSettingsStore } from '@/state/settings/editSettingsStore';

const map: any = {
  Montag: 1,
  Dienstag: 2,
  Mittwoch: 3,
  Donnerstag: 4,
  Freitag: 5,
  Samstag: 6,
  Sonntag: 7,
};

export function settingsPraxisColumnsDefs(): IRoseAGGridColumn<IZeiten>[] {
  return [
    {
      headerName: 'Wochentag',
      field: 'tagName',
      comparator: (valueA: any, valueB: any) => map[valueA] - map[valueB],
      sortable: true,
      sort: 'asc',
      flex: 1,
    },
    {
      headerName: 'Von',
      field: 'von',
      sortable: true,
      valueFormatter: params => (params.data ? `${uhrFilter.filters.uhr(params.data?.von)}` : ''),
      width: 80,
    },
    {
      headerName: 'Bis',
      field: 'bis',
      sortable: true,
      valueFormatter: params => (params.data ? `${uhrFilter.filters.uhr(params.data.bis)}` : ''),
      width: 150,
    },
    {
      headerName: 'Öffnungsdauer',
      field: 'stunden',
      sortable: true,
      cellStyle: { justifyContent: 'center' },
      valueFormatter: params => (params.data ? `${params.data.stunden} h` : ''),
      width: 150,
    },
    {
      headerName: '',
      field: 'tagName',
      cellRenderer: 'DeleteButtonCell',
      width: 50,
      cellStyle: { justifyContent: 'end' },
      cellRendererParams: {
        context: {
          itemValue: true,
          clicked(context: { row: IZeiten }) {
            console.log('clicked', context);
            editSettingsStore.commit.deleteOeffnungszeiten(context.row);
          },
        },
      },
    },
  ];
}
